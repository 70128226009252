import React from 'react';

const SquadCard = ({ img, alt, name, funRole, role, reverse, aboutMe, funFact, askMe, quote, quoteBy, why }) => {
  const renderQuote = () => {
    if (quote) {
      return (
        <p>
          <b>{`Favourite quote: `}</b>
          <i>{`" ${quote} " - ${quoteBy}`}</i>
        </p>
      )
    }
  }
  return (
    <div className={reverse ? 'landing-page-card reverse' : 'landing-page-card'}>
      <img className='support-squad-headshot' src={img} alt={alt} />
      <div className='squad-content'>
        <h5 className='underline'>{`I am ${name} - ${funRole}`}</h5>
        <p className='small-text'>{role}</p>
        <p>{aboutMe}</p>
        <p>
          <b>{`Fun fact about me: `}</b>{funFact}
        </p>
        <p>
          <b>{`Ask me about: `}</b>
          {askMe}
        </p>
        {renderQuote()}
        <p>
          <b>{`Why I joined Mama: `}</b>
          <i>{why}</i>
        </p>
      </div>
    </div>
  )
}

export default SquadCard;

