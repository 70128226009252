import React from 'react';
import ScrollToTop from '../../Components/ScrollToTop';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const style = {
  lotti: {
    width: '200px'
  }
};

const Payroll = () => (
  <div className='process-page'>
    <ScrollToTop />
    <div className='process-page-hero'>
      <lottie-player
        style={style.lotti}
        autoplay
        loop
        mode="normal"
        src='https://assets7.lottiefiles.com/animated_stickers/lf_tgs_YHXlAQ.json'
      />
      <h1>Payroll</h1>
    </div>
    <h4>Payment will be sent via Direct Deposit bi-monthly.</h4>
    <p>
      You have a pay sheet created privately for you on Google Sheets where you submit your bi-monthly pay. We will send you the link if you haven’t received it yet.
    </p>
    <p>
      <b className='underline'>It is your responsibility to fill out your paysheet on time.</b>
      {` Please refer to the table below to determine the date at which you need to have your pay sheet updated, and when the corresponding pay dates occur.`}
    </p>
    <br />
    <br />
    <TableContainer component={Paper}>
      <Table aria-label="payroll table">
        <TableHead>
          <TableRow>
            <TableCell align='center'>Service Rendered Dates</TableCell>
            <TableCell align='center'>Pay Sheet Cut Off Date</TableCell>
            <TableCell align='center'>Pay Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align='center' component="th" scope="row">1st - 14th</TableCell>
            <TableCell align='center'>20th</TableCell>
            <TableCell align='center'>1st of the following month</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='center' component="th" scope="row">15th - end of month</TableCell>
            <TableCell align='center'>5th of the following month</TableCell>
            <TableCell align='center'>15th of the following month</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <h4>Importantly…</h4>
    <p>
      Banks do not process direct deposits on weekends. Therefore if the 1st or the 15th falls on a weekend or public holiday, your pay date will either be on the Friday or the Monday around the dates. Don’t worry if you haven’t received your pay by the Friday, it is coming!
    </p>
    <p>
      If you miss the pay sheet cut off dates you will not be paid on the requisite pay dates. Your payment will be added to the next pay period. 
    </p>
    <h4>HOW TO FILL OUT YOUR PAYSHEET</h4>
    <ol>
      <li>
        Please go to the correct tab on the paysheet for your pay period. Each pay period is split into different tabs that are situated at the bottom of the Google Sheet.
      </li>
      <li>
        Fill out the date, customer name, and select the length of time for the service you completed for that particular mama. The Google sheet will automatically fill out the payment amount and HST (if applicable).
      </li>
      <li>
        If you have a client that has two services (e.g. Busy Mama and Facial massage), list each service in separate rows one under the other.
      </li>
      <li>
        Management will add in TIPS collected from bookings made online into your paysheet. If you receive tips from mama’s on the day, please do not input them into this sheet.
      </li>
    </ol>
    <p><b>
      Note that you DO NOT need to send the sheet to management as Google Sheets automatically saves and shares the sheets with us.
    </b></p>
    <p>If you have any pay sheet or pay submission question, please ask Cherry directly.</p>
  </div>
);

export default Payroll;