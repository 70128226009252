import React from 'react';
import ScrollToTop from '../../Components/ScrollToTop';

const style = {
  width: '200px',
};

const Cancellation = () => (
  <div className='process-page'>
    <ScrollToTop />
    <div className='process-page-hero'>
      <lottie-player
        style={style}
        autoplay
        loop
        mode="normal"
        src='https://assets3.lottiefiles.com/animated_stickers/lf_tgs_4xuFMB.json'
      />
      <h1>Cancellation Policy</h1>
    </div>
    <h4>CANCELLATIONS / RESCHEDULING - BEFORE 24 HOURS PRIOR TO THE APPOINTMENT</h4>
    <p>
      You are responsible for working whenever there is a client booked in your schedule! 
      <b className='underline'>{` If you need to cancel before 24 hours prior to the appointment we kindly ask you to reschedule with your client. `}</b>
      Please contact them via phone (call only - no texting) and try to find a date to reschedule on the spot. Do this as soon as you know you need to!!
    </p>
    <p>
      <u>If you do reschedule on the spot:</u>
      {` please let us know the new appointment time and details immediately via your personal group thread in Slack so that we can book that in at our end. Remember, if we don’t receive communication of the new appointment details we don’t know about the appointment and therefore cannot update the RMT Portal, JaneAPP or guarantee payment for the appointment.`}
    </p>
    <p>
      <u>If you cannot rebook on the spot:</u>
      {` please start the process and gather important rebooking details such as their availability and time and day preferences so we can keep track of and help you make a rebooking. Make sure you continue to try and rebook with your client and keep us posted.`}
    </p>
    <p><b className='underline'>Make sure to let the admin know BEFORE you reach out to your client.</b></p>
    <p>
      The best way to get in touch with us is via Slack in your personal group thread with the whole management team (e.g. name_mamamobile). Please type @channel at the beginning of your message so we all get a notification that you have messaged in the thread. Do not contact us individually via direct message on Slack as we simply may not see this message fast enough.
    </p>
    <p><i>
      The reason we get YOU to contact the client is because it comes across as a more genuine cancellation, particularly if you already have a relationship with that client. Your mama’s have been looking forward to their treatment, and we believe they’d rather hear from you than a stranger on the phone. This process also cuts out the middleman with a lot of going back and forth to find a reschedule time, plus they’ll be more likely to re-book with you, as they’re speaking directly with you and not looking into seeing another RMT instead.
    </i></p>
    <p><i>
      We want YOU to build rapport with your client, so that they want to come back and see YOU!
    </i></p>
    <p>
      Even if you think you may have to cancel for whatever reason, at least just let US know even before you consider contacting the client so that we are prepared and have a heads up! We get stuff that happens! But it is always best for us to know as soon as possible. 
      <b className='underline'>Don’t wait until the last minute.</b>
    </p>
    <h4>What if you don’t want to do the rescheduling you ask?</h4>
    <p>
      If you don’t want to reschedule the appointment yourself and would rather our management team do the entire reschedule process for you, there will be an administrative fee of $45 taken out of your next pay run. The process of management going back and forth with the client as a middle man to find a new time for the rescheduled appointment takes away significant time away from booking new appointments to support you and your teammates. Please know we are here to support you, but reschedules can easily get out of control. Of course once you’ve organised the appointment and let us know, we still do the rest of the administrative processes to ensure the appointment is in the system and you are paid for it. 
    </p>
    <div className='takeaway-box'>
      <h4>THE GOLDEN RULE OF CANCELLATIONS / RESCHEDULING PRIOR TO 24 HRS</h4>
      <ol>
        <li>Contact management to let us know about the cancellation</li>
        <li>Call your client (do not text)</li>
        <li>Try and rebook with your client on the spot. If not please start the process and retrieve relevant details. Keep trying to rebook</li>
        <li>If / when you successfully rebook the client let the management team know</li>
      </ol>
    </div>
    <h4>WHEN YOU CANCEL WITHIN 24 HOURS PRIOR TO THE APPOINTMENT</h4>
    <p>
      <b className='underline'>If you need to cancel with a client within 24 hours before the appointment time, you need to contact management IMMEDIATELY and let us know what has happened.</b>
      {` We will then contact the client and let them know about the cancellation. Once we have successfully reached the client, if they still would like to reschedule with you we ask that you then contact the client to organise a reschedule time as per the rescheduling process. Again, please let us know as soon as you make a new appointment time.`}
    </p>
    <p>
      <b className='underline'>If you cancel with a client within 24 hours before the appointment time, you will be charged an administrative fee of $45.</b>
      {` This will come out your next pay run.`}
    </p>
    <p><i>
      We are doing this because...
    </i></p>
    <p>
      Cancellations within the 24 hour period have extensive monetary and productivity costs to the team + a negative impact on brand reputation and client trust. They take up so much administrative time, and we often have to compensate the client with discounts or free services to make up for their disappointment. In short, we simply can’t afford to have cancellations at this late point in time.
    </p>
    <p>
      We charge clients for the full amount of their appointment fee if they cancel within 24 hours prior to their appointment. This is to ensure you as the RMT are not put out by their cancellation. Therefore it is only fair that we apply a similar rule to RMT’s so that mama’s are also not put out by cancellations going the other way.
    </p>
    <p>
      Keep in mind that last minute cancellations have a negative impact on your individual practice as an RMT. Your clients will be disappointed as they’ve been looking forward to seeing you! Plus they may not have the flexibility in their schedule or want to wait to reschedule with you, and you may lose their regular appointments to another RMT in the process. Remember - you want to keep them coming back to YOU!
    </p>
    <p><i>
      Please, always think strongly about whether you really need to cancel. Cancellations are not ideal and should be avoided at all times if possible.
    </i></p>
    <p><i className='underline'>
      We want happy mamas and happy RMTs!
    </i></p>
    <div className='takeaway-box'>
      <h4>GOLDEN RULE OF CANCELLATIONS WITHIN 24 HRS PRIOR TO THE APPOINTMENT</h4>
      <ol>
        <li>Contact management to let us know IMMEDIATELY about the cancellation</li>
        <li>We will contact the client - so hold tight</li>
        <li>Once we have contacted the client, if they would still like to reschedule with you, you will need to reach out to the client to organise the new appointment</li>
        <li>When you successfully rebook the client let management know</li>
      </ol>
    </div>
    <h4>CANCELLATIONS BY CLIENTS</h4>
    <p>
      <b className='underline'>Whenever a client cancels we always encourage clients to rebook at another time or day with you and not just cancel.</b>
      {` The ideal situation is to turn a cancellation into a rescheduled appointment, and we all need to try our best to achieve this when cancellations arise.`}
    </p>
    <p>
      {`Whenever we at the management team receive notice of a cancellation we let you know as soon as we see it! `}
      <b className='underline'>We always send across a message via Slack in your personal group thread and we will always tag you directly.</b>
    </p>
    <p>
      <b className='underline'>If your mama contacts you to cancel, please let management know immediately via Slack - don’t forget to write @channel in your message.</b>
      {` Please send this message via Slack in your personal group thread with the whole management team (e.g. name_mamamobile). The #admin channel some of you have been using for these notifications is now obsolete, please do not use it anymore.`}
    </p>
    <p>
      When a mama contacts us to reschedule, we always reach out to ask you if it’s ok first. Likewise we will send you a message via Slack. In situations where rescheduling cannot be achieved and the mama wants to cancel the appointment we then follow the appropriate cancellation policy as detailed below.
    </p>
    <div className='takeaway-box'>
      <h4>Client cancellation takeway!</h4>
      <ul>
        <li>
          Whenever a client wants to cancel an appointment, we always try and encourage them to reschedule and not cancel. We want to keep these mama’s in the squad!
        </li>
        <li>
          We will always send you a notification via Slack in your personal group thread if we receive the notice. If you receive the notice please contact us as soon as you hear!
        </li>
      </ul>
    </div>
    <h4>WHEN YOUR MAMA CANCELS BEFORE 24 HOURS PRIOR TO THE APPOINTMENT</h4>
    <p>
      When your mama cancels before 24 hours prior to the appointment they are entitled to a full refund and are able to fully cancel the appointment without penalty. Therefore we are unable to pay you for the appointment.
    </p>
    <p>
      As mentioned before, when we receive a notification from a client that they’d like to cancel we let you know immediately via the Slack personal group thread. 
    </p>
    <p>
      <b className='underline'>If once the mama officially cancels, you’d like us to re-add that allotted time back into your RMT Portal so you will be available for online bookings, please let us know.</b>
      {` We don’t automatically add the time back in to your schedule, just in case for whatever reason you may not be able to service your normal radius during that time period. Please make sure you let us know if you’d like us to do this for you. Remember, you and only you are responsible for the time you have available online in the portal.`}
    </p>
    <h4>WHEN YOUR MAMA CANCELS WITHIN 24 HOURS PRIOR TO THE APPOINTMENT</h4>
    <p>
      <b className='underline'>When your client cancels within 24 hours prior to the appointment, the client will be charged the full appointment fee and you will still be paid for the appointment.</b>
      {` We don’t want you to be put out by late cancellations as we know you have reserved the time for this client, and therefore you deserve to be paid. The ONLY exception to this rule is in situations where the mama goes into labor. We keep the full payment from the client for the original appointment and reschedule it as a postnatal appointment with YOU once she has recovered from the birth. Unfortunately we cannot pay you for the appointment at the original time, however this WILL be a rescheduled appointment later in time and you will be paid based on the rescheduled appointment time.`}
    </p>
    <p>
      <b className='underline'>When the situation arises where a mama cancels within 24 hours and you are getting paid for the appointment, you are still responsible for this time slot with Mama Mobile.</b>
      {` In these situations we may be able fill the time with an appointment with another mama - which would be awesome and result in even more financial reward for you! For this to happen, we still need you to be available, so please ensure that you don’t book external appointments with clients or for yourself during this time you’d be paid for.`}
    </p>
    <p>
      If you have a relationship with your client and you’re happy to reschedule instead of apply the full cancellation fee, that is absolutely fine! We never ever suggest this to the customer when they contact us to cancel within 24 hours prior to the appointment. This is purely something to just keep in the back of your mind and it is for YOU ONLY to offer if it’s something you’re happy to do. So when we do chat to you about the cancellation, if this is something you’re open to doing with particular clients let us know. Just remember if you choose this you won’t get paid based on the original appointment time, your pay will move to the new appointment time.
    </p>
    <div className='takeaway-box'>
      <h4>DON’T FORGET!!</h4>
      <ul>
        <li>If a mama cancels within 24 hours, you'll still be compensated for the booking except for when she's going into labor!</li>
        <li>You are responsible for your time at Mama Mobile for the time slot that you are getting paid for if a client cancels less than 24 HRS prior to your appointment.</li>
        <li>Do not book other appointments for yourself or external clients into this spot.</li>
      </ul>
    </div>
  </div>
);

export default Cancellation; 