import React from 'react';
import ProcessCard from './ProcessCard';
import './process.css';
import ScrollToTop from '../../Components/ScrollToTop';
import HeroAnimation from '../../Components/HeroAnimation/HeroAnimation';

const PROCESS = [
  {
    process: 'Availability',
    animation: 'https://assets3.lottiefiles.com/animated_stickers/lf_tgs_Fc5J0c.json',
    uri: 'availability',
  }, 
  {
    process: 'Cancellation Policy',
    animation: 'https://assets3.lottiefiles.com/animated_stickers/lf_tgs_4xuFMB.json',
    uri: 'cancellation',
  },
  {
    process: 'Payroll',
    animation: 'https://assets7.lottiefiles.com/animated_stickers/lf_tgs_YHXlAQ.json',
    uri: 'payroll',
  },
  {
    process: 'Appointment Policy',
    animation: 'https://assets10.lottiefiles.com/animated_stickers/lf_tgs_VDtWMS.json',
    uri: 'appointment-policy',
  },   
];

const Process = () => {
  return (
    <div className='landing-page'>
      <ScrollToTop />
      <div className='hero-img'>
        <h1>get excited about our process!</h1>
        <HeroAnimation animation="https://assets5.lottiefiles.com/packages/lf20_BqaWVc/teamwork_4.json" />
      </div>
      <div className='process-wrapper'>
        {PROCESS.map(({ process, animation, uri }) => <ProcessCard key={process} process={process} animation={animation} uri={uri} />)}
      </div>
    </div>
  )
}

export default Process;