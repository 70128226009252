/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import './warningBox.css';

const WarningBox = ({ error }) => (
  <div className={error ? 'warning-box' : 'warning-box hide'}>
    <Icon name="warning sign" size="large" />
    <div>{error}</div>
  </div>
);

export default WarningBox;
