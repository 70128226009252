import React from 'react';
import { NavLink } from 'react-router-dom';

const ProcessCard = ({ animation, process, uri }) => {
  return (
    <NavLink to={`/process/${uri}`}>
      <div className='process-card'>
        <lottie-player
          autoplay
          loop
          mode="normal"
          src={animation}
        />
          <h4>{process}</h4>
      </div>
    </NavLink>
  )
}

export default ProcessCard;