import React from 'react';
import './landingPage.css';
import HeroAnimation from '../../Components/HeroAnimation/HeroAnimation';
import FoundingStory from './FoundingStory';
import Mission from './Mission';
import Vision from './Vision';
import Values from './Values';
import Process from './Process';
import MamaU from './MamaU';
import SupportSquad from './SupportSquad';
import ScrollToTop from '../../Components/ScrollToTop';

const LandingPage = () => {
  return (
    <div className='landing-page'>
      <ScrollToTop />
      <div className='hero-img'>
        <h1>Welcome to Mama Squad</h1>
        <HeroAnimation animation="https://assets5.lottiefiles.com/packages/lf20_BqaWVc/teamwork_4.json" />
      </div>
      {/* <FoundingStory /> */}
      <Mission />
      <Vision />
      <Values />
      <Process />
      <SupportSquad />
      <MamaU />
    </div>
  )
}

export default LandingPage;