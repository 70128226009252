import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import './header.css';
import Nav from './Nav';
import MobileNav from './MobileNav';

const Header = ({ isFirstLoad, changeFirstLoad }) => (
  <div>
    <div id='header'>
      <div id="logo"> 
        <Link to='/home'>
          mama mobile
        </Link>
      </div>
      {/* {Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 700 ? <Nav />: <MobileNav />} */}
      <div id='social-media'>
        <a href='https://www.instagram.com/mamamobilemassage/' target='_blank'>
          <Icon name='instagram' size='big' />
        </a>
        <a href='https://www.facebook.com/mamamobilemassagetherapy/' target='_blank'>
          <Icon name='facebook' size='big' />
        </a>
      </div>
    </div>
  </div>
);

export default Header;