import React from 'react';
import ScrollToTop from '../../Components/ScrollToTop';

const style = {
  width: '200px',
};

const AppointmentPolicy = () => (
  <div className='process-page'>
    <ScrollToTop />
    <div className='process-page-hero'>
      <lottie-player
        style={style}
        autoplay
        loop
        mode="normal"
        src='https://assets10.lottiefiles.com/animated_stickers/lf_tgs_VDtWMS.json'
      />
      <h1>Appointment Policy</h1>
    </div>
    <h4>CHECKLIST - 24 HOURS BEFORE YOUR FIRST APPOINTMENT FOR THE DAY</h4>
    <h4>1) Verify your directions</h4>
    <ul>
      <li>
        Look at the client’s address on file. Check both RMT Portal + JaneAPP to make sure they are consistent and both on file
      </li>
      <li>
        Double check on Google Maps. Take a peek at the photo that shows up so you can have a look at what type of residence you’ll be visiting
      </li>
      <li>
        Ensure their parking instructions make sense to you - if you need clarification please call the mama! 
      </li>
      <li>
        Ensure that they have included the buzzer code and note down any other instructions in the JaneAPP notes section. 
      </li>
      <li>
        Do this for every mama for your day. It is also wise to check your driving route between appointments too. 
      </li>
    </ul>
    <h4>2) Pack your massage bag</h4>
    <ul>
      <li>2 flat sheets + 1 blanket</li>
      <li>Head-rest cover</li>
      <li>Table + headrest</li>
      <li>Charged phone or device to play music with</li>
      <li>Speaker</li>
      <li>2+ pillows</li>
      <li>Large towel or alternate sheet for breast support</li>
      <li>Lysol wipes</li>
      <li>Hand sanitiser</li>
    </ul>
    <p>
      How you pack your bag is up to you. However we suggest a rolled suitcase as it works great! This option is great because…
    </p>
    <ul>
      <li>You don’t have to carry it. The more you can drag the better!</li>
      <li>You can use the netted half to store used sheets</li>
    </ul>
    <h4>3) Music</h4>
    <p>
      We have 4 playlists available on Spotify for you to play while performing your massage service. Please follow our playlists!
    </p>
    <ul>
      <li>Spa / zen</li>
      <li>Nature sounds</li>
      <li>Piano / light jazz</li>
      <li>Acoustic</li>
    </ul>
    <p>
      When selecting if or which music to play please ask your client these questions: 
    </p>
    <ol>
      <li>Would you like to play music during the massage today?</li>
      <li>I have a few playlists with me but would also be happy to listen to whatever you have that is most relaxing for you</li>
      <li>If they say they want ours please provide them with the selection that we have</li>
    </ol>
    <p>
      At Mama, we use Spotify. If you opt for the premium version (around $10 a month) you can save our playlists and listen offline so you don’t use data. This is also another thing that can be expensed at tax time.
    </p>
    <p><b className='underline'>*If anyone would like to be added to our account please let Sarah know*</b></p>
    <h4>WHEN YOU ARRIVE AT YOUR APPOINTMENT</h4>
    <div className='takeaway-box'>PLEASE MAKE SURE TO ARRIVE TO THE DOOR 10 MINUTES PRIOR TO YOUR APPOINTMENT START TIME</div>
    <p>Please arrive at your clients door 10 minutes prior to the scheduled appointment start time.</p>
    <p>
      It is SO important that you are on time for your appointments. If you’re late to your appointment, mama’s can get really worried and upset as they think you might not be coming or are in some sort of trouble (like a traffic accident). We often get worried phone calls! It is also particularly important to be on time if you have appointments scheduled later in the day, as you don’t want to push more appointments later too.
    </p>
    <p>
      If you foresee yourself being late to your appointment due to traffic or delays with previous appointments, please let the management team know immediately and contact your client to let them know. It’s far better to give your client the heads up that you might be late and exceed their newly set expectations by ending up being on time, as opposed to being uncontactable with no one knowing where you are. Communication is key!
    </p>
    <p>
      Please also remain in contact with us during this period and let us know when you end up arriving at your appointment. Do not ever answer your phone unsafely or illegally when driving. However if you need to please pull over into a safe space and give us a call. Also please let us know when you eventually arrive at the appointment by a quick ‘I’m there’ type message in our Slack thread with you.
    </p>
    <h4>What if there is a parking fee involved?</h4>
    <p>We have outlined in the intake form that mama’s fill out, that any parking fees are the responsibility of the client.</p>
    <p>
      If the client reimburses you with cash on the spot, please feel free to take this as this will be a much faster way to receive reimbursement. Alternatively if you need to claim it through us, please follow the below steps. 
    </p>
    <ol>
      <li>Take a photo of the parking fee</li>
      <li>Send to admin: hello@mamamobile.com after your appointment with the subject line ‘(Your name) - Parking FEE - (Client Name) - (Date)'</li>
      <li>You will be reimbursed the amount on your next pay</li>
    </ol>
    <p><b>
      {`Parking fees will no longer be reimbursed if it’s not sent in to management `}
      <span className='underline'> within 3 DAYS of the appointment.</span> 
      {` After this point in time we can no longer charge the client and therefore you cannot get reimbursed.`}
    </b></p>
    <h4>What if I can’t find a street parking spot when this is what the client suggested?</h4>
    <p>If there aren’t any parking spots on the street as the client suggested, please give them a call to let them know what happened. Hopefully you can both come to a solution. If you do end up finding a spot please follow the parking reimbursement protocol as outlined above.</p>
    <h4>How do I avoid walking far with my table?</h4>
    <p>We have outlined in the intake form that our RMT’s will not walk further than 50m with the table. We want to ensure that you are never overexerting yourself.</p>
    <p>If you arrive and the parking garage or street parking spot is further than this please adhere to the following:</p>
    <ol>
      <li>Give the client a call, ask if there is any parking closer than X spot</li>
      <li>If she says no - let her know that you will drop your things at the front and then go park. Pull up as close as you can with your 4 ways on</li>
      <li>If it is a secure building you will often be able to leave your things with the front desk. If you are nervous please ask that your client to meet you at the front.</li>
    </ol>
    <h4>How do I notify the client that I have arrived?</h4>
    <p>
      <b className='underline'>NEVER ring the doorbell, EVER!</b>
      {` This can’t be emphasised enough. Since you are often coming during babies nap time we have to be really cautious not to wake the baby.`} 
    </p>
    <p>
      Knock very lightly, and hopefully mama will be listening and be close by the door. Please wait for 2 minutes, and if she doesn’t come to the door please send her a quick message. We suggest the message to be as follows:
    </p>
    <p><i>“Hi (Mama name), it’s (Your name) from Mama Mobile. I am here!”</i></p>
    <h4>How do I announce myself at the door?</h4>
    <p>Your initial conversation should go something like this when mama answers the door…</p>
    <p><i>“Hi (Mama name), it’s so nice to meet you. I am (Your name) from Mama Mobile! How is your day going?”</i></p>
    <p><b className='underline'>Greet her with a smile. And always centre the conversation around her.</b></p>
    <p>
      Mama will be letting you into her home. Make sure to take off your shoes and not leave your items strewn around the house. You may choose to bring your own slippers etc. If your table is in a bag and it is a rainy or snowy day please take it out of the bag at the front door.
    </p>
    <h4>SET-UP</h4>
    <p><i>Always ask your client - “Do you have somewhere in mind you would like to set up today?”</i></p>
    <p>
      {`If this is mama’s first time she may be a little bit nervous. If she asks what you suggest we suggest that you ask if you can take a look around the main floor. Focus on finding a space that will ensure that you have enough room, and consider if the space has a nice vibe for the massage. `}
      <b className='underline'>You can say no to treating on a floor if it isn’t the main floor. You may also have to move a few things around.</b>
    </p>
    <p>
      When mama fills out the intake form they will be reminded to ensure that the treatment occurs on the main floor to avoid the use of stairs. In some situations the main floor will not be appropriate for treatment, or the house itself requires stairs to be walked up to be reached. In these cases if we are notified of this we ALWAYS reach out for more information from the client and check to see whether you will be ok with whatever stairs are at the location.
    </p>
    <p><b>
      Once you secure a space we suggest saying - “It will just take me about 5 minutes to set up! If you need to check on baby or get anything ready feel free - you can also stick around to chat!”
    </b></p>
    <p>
      Sometimes mama will stick around, and others will go do something and come back.
    </p>
    <p>
      Make sure to have the table set up as best you can. Please dim the lights and do everything you can to make the situation as zen as possible. Start the music if your client would like this playing during the treatment. 
    </p>
    <h4>Initial intake</h4>
    <p>
      Once you are set up and ready to start the massage please ask any initial questions as you would at a regular clinic. Please figure out the mama’s target areas and preferences for their massage. Offer suggestions to their concerns, you’re the expert!
    </p>
    <h4>How do I give the client privacy to change?</h4>
    <p>Once you have set up and gone over any initial questions and are ready to roll, please say the following:</p>
    <p><i>
      “I will let you get onto the table while I wash my hands. I will be sure to holler around the corner before I re-enter the room. You can dress down to your level of comfort, we will only be uncovering the areas that we are working on today”
    </i></p>
    <h4>Timing</h4>
    <p>
      The massage is the amount of time booked (60 minutes, 90 minutes etc) from the minute you start the hands on massage. Please use a timer. This seems menial however we have had mamas come back saying they didn’t receive x amount of time and we want to make sure that they have a full experience. We also don’t want you to go over time!
    </p>
    <h4>POST MASSAGE PROTOCOL</h4>
    <p>Once you have finished the massage let the mama know and allow her to relax. We suggest in most situations saying:</p>
    <p><i>“I am going to go wash my hands. Please get up nice and slowly. I’ll holler before I come back”</i></p>
    <p><i>
      <b className='underline'>If this is a prenatal mama</b> 
      {` “I am going to go wash my hands, do you need any help getting up? Be sure to get up slower than you would like to ensure that you do not feel light headed or dizzy- make sure to sit before you stand”`}
    </i></p>
    <p><i>
      <b className='underline'>If the baby has been laying on the table with mama</b> 
      {` “I am going to go wash my hands, would you like me to hold the baby while you change?”`}
    </i></p>
    <p>
      Once the mama is settled and up off the massage table 
      <b>{` please educate the mama on what you worked on, and how she can continue to feel her best. `}</b>
      This information should all be re-communicated in your client follow up information that you submit to us and is sent onto the client via email later.
    </p>
    <p>We suggest saying the following once she is back:</p>
    <p><i>
      “I am going to get everything all cleaned up! If you need to do anything I can always let myself out. I want to show you (type of stretch) for (massage concern) before I leave! I would love to see you again in (number) weeks to keep working on (concern)” 
    </i></p>
    <h4>Rebooking on the spot</h4>
    <p>
      <b className='underline'>Whilst you are chatting with your mama about how she can continue to feel her best, please also advise her when her next appointment should be.</b>
      {` This is the perfect opportunity to organically try and get a rebooking on the spot!`}
    </p>
    <p>
      We know that rebooking a client on the spot can seem a little invasive, but the truth is many mama’s just don’t know when they should come back! Most of our clients have enough insurance to see us once a month.
    </p>
    <p>
      Try to use your very best judgement as to when you genuinely feel they would benefit from seeing you again. Let her know that she can cancel or change the appointment up to 24 hours prior to the appointment if she needs to, but that it is always nice to have something in place to ensure that you don’t book up!
    </p>
    <p>
      If you do manage to rebook another appointment please let management know via Slack in your personal group thread. We always follow up with the client via email to make sure everything is fully confirmed, and we will add the appointment into the RMT Portal and JaneAPP. Remember, if we don’t know the appointment exists we can’t input this information or ensure that you get paid.
    </p>
    <div className='takeaway-box'>
      <h4>REBOOKING TIPS</h4>
      <p style={{marginTop: 0}}>Keep it organic and natural</p>
      <ul>
        <li>The best time to suggest rebooking is after you tell the mama when it would be ideal to see you next</li>
        <li>Let management know immediately about new appointments via Slack</li>
      </ul>
    </div>
    <h4>Cleaning up and getting ready to leave</h4>
    <p>Once you have had a quick chat and hopefully had a chance to rebook, you can start cleaning up your things and get ready to leave.</p>
    <ol>
      <li>Strip the table. Make sure to put dirty sheets somewhere obviously separate from clean items. A good spot is a laundry bag, or the netted part of a suitcase</li>
      <li>Wipe down the table</li>
      <li>Pack up the rest of your things</li>
      <li>Turn off the music. Make sure this is the last thing that you do.</li>
    </ol>
    <p><b>
      Make sure you say a warm and friendly goodbye to your client! Wish them well and mention that you look forward to treating them again soon!
    </b></p>
    <h4>POSTNATAL MAMAS</h4>
    <p>Postnatal mamas may be in more pain, have very specific areas they need work on or be particularly sensitive in terms of self confidence.</p>
    <p><b className='underline'>
      Please make sure to let all postnatal mama’s know: “No worries if you are leaking today!”
    </b></p>
    <p>
      You may also need to add additional support for breasts, always ask how tender she is feeling before you start the massage. 
    </p>
    <h4>If Baby Cries</h4>
    <p>
      <b>Please don’t say anything until mama does.</b>
      You never know what type of sleep training or parenting style is happening, try and be as neutral as possible. If mama starts looking apprehensive or can’t relax here are some ways to offer her non-judgemental support.
    </p>
    <p><i>
      It might be nice to say: “Hey, don’t worry at all if you want to check on baby. We do have extra time and can totally take a little break if you want! We will just resume afterwards” 
    </i></p>
    <p>
      If mama has brought baby into the room I suggest a few things based on where the baby can see mama: 
    </p>
    <ul>
      <li>Any position where the baby can see mama: whether this be in the carrier, play mat etc. usually I try to place them under the head rest so that they can see mamas face.</li>
      <li>On table with mama in a side lying position: this works best for newborns or babies who really just want to nurse.</li>
    </ul>
    <p>
      On very rare occasions there may be a complete baby freak out that distracts the appointment sometimes to the extent that the mama ends up not receiving her massage. In situations like this it might be nice to say:
    </p>
    <p><i>
      “I know baby is having a bit of a day. I have someone scheduled after you so I can’t stay too much longer I’m so sorry. This honestly totally happens sometimes, life is so unpredictable! I want to make sure you get your full time for massage so let’s reschedule. Our admin will get in touch with you” 
    </i></p>
    <p>
      Make sure let management know via our Slack thread, and explain what has happened to admin and we will sort out the rest.
    </p>
  </div>
);

export default AppointmentPolicy;