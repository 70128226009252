import React from 'react';
import { NavLink } from 'react-router-dom';

const Process = () => (
  <div className='landing-page-section'>
    <h3>our process</h3>
    <div className='landing-page-card reverse'>
      <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1580627573/welcome/_K3A8573_bw05ce.jpg' />
      <p>
        {`To ensure Mama runs smoothly, we need to make sure our squad is on the same page regarding our processes. Please get familiar with all of our processes and if you are unsure of anything - don't hesitate to ask! `}
        <NavLink className='underline' to='/process'>I'm ready to learn Mama Processes!</NavLink>
      </p>
    </div>
  </div>
);

export default Process;