import React from 'react';
import { NavLink } from 'react-router-dom';

const SupportSquad = () => (
  <div className='landing-page-section'>
    <h3>our support squad</h3>
    <div className='landing-page-card'>
      <img src="https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/_K3A0036_1_ifybqa.jpg" alt="Sarah Rennick (RMT) massaging" />
      <p>
        {`We are the support squad and our job is here to support you - `}
        <NavLink className='underline' to='/support-squad'>Click me to learn more about us!</NavLink>
      </p>
    </div>
  </div>
);

export default SupportSquad;