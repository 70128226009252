import React from 'react';

const MamaU = () => (
  <div className='landing-page-section'>
    <h3>mama-U</h3>
    <div className='landing-page-card reverse'>
      <img src="https://res.cloudinary.com/dhze7gimq/image/upload/v1580495891/welcome/IMG_2882_se7yvb.jpg" alt="Sarah Rennick (RMT) massaging" />
      <div>
        <p>
          At Mama, we are all about personal and professional development. We have created Mama-U for you to continue your growth!
        </p>
        <p>
          Mama-U is currently under construction, so please check back soon!
        </p>
      </div>
    </div>
  </div>
);

export default MamaU;