import React from 'react';
import '@lottiefiles/lottie-player';

const style = {
  width: '180px',
}

const Values = () => (
  <div className='landing-page-section'>
    <h3>our values</h3>
    <div className='landing-page-card'>
      <lottie-player
        style={style}
        autoplay
        loop
        mode="normal"
        src="https://assets9.lottiefiles.com/animated_stickers/lf_tgs_eNR1ji.json"
      />
      <div className='landing-page-card-content'>
        <h5 className="underline">Growth Mindset</h5>
        <ul>
          <li>I will not present a negative experience without a lesson.</li>
          <li>I will always share the lesson to help elevate our process.</li>
        </ul>
      </div>
    </div>
    <div className='landing-page-card'>
      <lottie-player
        style={style}
        autoplay
        loop
        mode="normal"
        src="https://assets8.lottiefiles.com/animated_stickers/lf_tgs_Ndq8xg.json"
      />
      <div className='landing-page-card-content'>
        <h5 className="underline">Deliver Delight</h5>
        <ul>
          <li>I will find small ways to make someone’s day better.</li>
          <li>I will add value to each interaction and find ways to make others smile.</li>
        </ul>
      </div>
    </div>
    <div className='landing-page-card'>
      <lottie-player
        style={style}
        autoplay
        loop
        mode="normal"
        src="https://assets1.lottiefiles.com/animated_stickers/lf_tgs_33gl75.json"
      />
      <div className='landing-page-card-content'>
        <h5 className="underline">Take Ownership</h5>
        <ul>
          <li>I am not afraid to make mistakes or admit when I am wrong.</li>
          <li>I will be patient with myself and others to find the learnings from each mistake.</li>
        </ul>
      </div>
    </div>
    <div className='landing-page-card'>
      <lottie-player
        style={style}
        autoplay
        loop
        mode="normal"
        src="https://assets1.lottiefiles.com/animated_stickers/lf_tgs_Lip7MF.json"
      />
      <div className='landing-page-card-content'>
        <h5 className="underline">Everything is Figureoutable</h5>
        <ul>
          <li>I will conduct myself with confidence and trust that I have the ability to be resourceful.</li>
          <li>I will not shy away from challenges and will use them to further my personal growth and the growth of Mama Mobile.</li>
        </ul>
      </div>
    </div>
    <div className='landing-page-card'>
      <lottie-player
        style={style}
        autoplay
        loop
        mode="normal"
        src="https://assets1.lottiefiles.com/animated_stickers/lf_tgs_0n5Bpb.json"
      />
      <div className='landing-page-card-content'>
        <h5 className="underline">Lean into Discomfort</h5>
        <ul>
          <li>I understand personal and professional growth comes from challenging my comfort zone.</li>
          <li>I will not fear new challenges and will approach them with optimism - finding joy in knowing that the discomfort is pushing me to grow.</li>
        </ul>
      </div>
    </div>
  </div>
);

export default Values;