import React from 'react';

const Mission = () => (
  <div className='landing-page-section'>
    <h3>our mission</h3>
    <div className='landing-page-card reverse'>
      <img src="https://res.cloudinary.com/dhze7gimq/image/upload/v1580415670/welcome/IMG_2896_2_p3uegb.jpg" alt="Team photo of mama mobile" />
      <div>
        <h5 className="underline">TO YOU</h5>
        <p>
          We promise to provide you with the support you need to build a successful practice that <b>sparks joy</b>.
        </p>
        <p>
          We promise to empower you to continue your professional and personal development.
        </p> 
      </div>
    </div>
    <div className='landing-page-card'>
      <img src="https://res.cloudinary.com/dhze7gimq/image/upload/v1580627077/welcome/_K3A8948_o30pzc.jpg" alt="mamas with their baby" />
      <div>
        <h5 className="underline">TO OUR MAMAS</h5>
        <p>
          We promise to <b>show up</b> for mothers from the moment they become pregnant.
        </p>
        <p><i>What does it mean to show up?</i></p>
        <ul>
          <li>
            to provide emotional and physical support
          </li>
          <li>
            to listen without judgement
          </li>
          <li>
            to meet them where they are at
          </li>
          <li>
            to educate
          </li>
          <li>
            to deliver smiles and delight
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Mission;