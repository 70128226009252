import React from 'react';
import '@lottiefiles/lottie-player';

const Vision = () => (
  <div className='landing-page-section'>
    <h3>our vision</h3>
    <div className='landing-page-card reverse'>
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets4.lottiefiles.com/packages/lf20_FizrwJ.json"
      />
      <p>
        We want to be the trusted support system for mothers of every stage - to empower their motherhood journey through <b>education</b>, <b>in-home services</b>, and <b>community connections</b>.
      </p>
    </div>
  </div>
);

export default Vision;