import React from 'react';
import HeroAnimation from '../../Components/HeroAnimation/HeroAnimation';
import SquadCard from './SquadCard';
import ScrollToTop from '../../Components/ScrollToTop';
import './supportSquad.css';

const TEAM = [
  {
    name: 'Alisha',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/_K3A9944_bih4o5.jpg',
    role: 'RMT Leader',
    funRole: 'professional juggler',
    aboutMe: 'Hi I am Alisha! and I am an adventure and cat loving Australian. I’ve lived in Toronto for 2.5 years and am moving to the Rocky Mountains in April! My background is so varied, probably why I am now a professional juggler haha! Most recently I had my own photography business, and before that I had roles in Marketing and managing people. I absolutely love everything visual, and I am a massive foodie.',
    funFact: 'I studied ballet for 22 years! I used to go class after school almost every day, and continued right into university. These days I practice a lot of yoga and have recently fallen in love with ICE SKATING! Thank you CANADA!',
    askMe: 'really anything RMT related, I’m your girl. Oh and curating your best instagram photos😉',
    reverse: false,
    quote: false,
    quoteBy: '',
    why: 'I joined mama because I wanted to help the company grow and make sure Canada is full of happy people helping happy mamas! When I heard about the company I just thought it was such a great unique concept, and then after meeting the team I knew it was destiny',
  },
  {
    name: 'Lindsay',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/_K3A9898_qfuh3z.jpg',
    role: 'Community Leader',
    funRole: 'mama whisperer',
    aboutMe: '...',
    funFact: '...',
    askMe: '...',
    reverse: true,
    quote: false,
    quoteBy: '',
  },
  {
    name: 'Sarah',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/_K3A9865_1_zccqz7.jpg',
    role: 'co-founder + CEO',
    funRole: 'mama bear',
    aboutMe: '...',
    funFact: '...',
    askMe: '...',
    reverse: false,
    quote: false,
    quoteBy: '',
  },
  {
    name: 'Cherry',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/_K3A9907_1_eliq5n.jpg',
    role: 'co-founder + CTO',
    funRole: 'the handymam',
    aboutMe: `Hi, I am Cherry - I live in Vancouver! I was born in China and moved to Vancouver when I was 10 years old. I am a huge curious soul - please share anything you'd like with me! I find everything interesting 😊`,
    funFact: `I used to sing opera and dance Latin Ballroom competitively! I am now addicted to personal growth, podcasts, and audiobooks!`,
    askMe: `podcast and book recommendations, accounting/tax advice, payroll q's, and tech feature requests`,
    reverse: true,
    quote: "We are all a little weird and life's a little weird, and when we find someone whose weirdness is compatible with ours, we join up with them and fall in mutual weirdness and call it love.",
    quoteBy: 'Dr. Seuss',
    why: 'I have always been fascinated with helping people - being apart of Mama allows me to help thousands! I have a super-mama who has given up so much for me, so supporting mamas is something I am very passionate about 💛'
  },
]

const SupportSquad = () => (
  <div className='landing-page'>
    <ScrollToTop />
    <div className='hero-img'>
      <h1>get to know your support squad</h1>
      <HeroAnimation animation="https://assets5.lottiefiles.com/packages/lf20_BqaWVc/teamwork_4.json" />
    </div>
    {TEAM.map(({ name, img, role, funRole, aboutMe, funFact, askMe, reverse, quote, quoteBy, why }) => (
      <SquadCard 
        name={name} 
        role={role} 
        funRole={funRole} 
        key={name} 
        reverse={reverse} 
        img={img} 
        funFact={funFact}
        aboutMe={aboutMe}
        askMe={askMe}
        quote={quote}
        quoteBy={quoteBy}
        why={why}
      />
    ))}
  </div>
);

export default SupportSquad;