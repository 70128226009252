import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from './Pages/Header/Header';
// import Footer from './Pages/Footer/Footer';
import LandingPage from './Pages/LandingPage/LandingPage';
import SupportSquad from './Pages/SupportSquad/SupportSquad';
import Login from './Pages/Login/Login';
import Process from './Pages/Process/Process';
import Availability from './Pages/Process/Availability';
import Cancellation from './Pages/Process/Cancellation';
import Payroll from './Pages/Process/Payroll';
import AppointmentPolicy from './Pages/Process/AppointmentPolicy';


class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/home" component={LandingPage} />
            <Route path="/support-squad" component={SupportSquad} />
            <Route path="/process/availability" component={Availability} />
            <Route path="/process/payroll" component={Payroll} />
            <Route path="/process/cancellation" component={Cancellation} />
            <Route path="/process/appointment-policy" component={AppointmentPolicy} />
            <Route path="/process" component={Process} />
          </Switch>
          {/* <Footer /> */}
        </div>
      </Router>
    );
  }
}

export default App;
