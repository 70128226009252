import React from 'react';

const style = {
  width: '200px',
};

const Availability = () => (
  <div className='process-page'>
    <div className='process-page-hero'>
      <lottie-player
        style={style}
        autoplay
        loop
        mode="normal"
        src='https://assets3.lottiefiles.com/animated_stickers/lf_tgs_Fc5J0c.json'
      />
      <h1>RMT Availability</h1>
    </div>
    <div className='takeaway-box'>
      YOU, AND ONLY YOU, ARE RESPONSIBLE FOR ENSURING YOUR AVAILABILITY UPLOADED TO THE PORTAL IS ACCURATE AT ALL TIMES.
    </div>
    <p>
      You, and only you, are responsible for ensuring your availability uploaded to the portal is accurate at all times. 
      <b className='underline'>This means if your availability changes after when you first upload it you must update it as soon as you can.</b>
      In situations when you’re out and about and cannot physically update it yourself please let us know and we can update it, but that is the only exception.
    </p>
    <p>
      If your availability isn’t accurate online, this means that clients can book you when you’re not free and this causes huge problems. In this situation we treat this as a cancellation on your part, and you are responsible for contacting the client to let them know, and also for rescheduling with them. If of course you cannot come to a solution with the client we will have to take over and try pass the appointment on to another RMT - which isn’t something you should want!
    </p>
    <p>
      We have detailed instructions on how to do this in both print and video form. If you are uploading your availability and you have any problems please reach out and we will help you!
    </p>
    <p>
      <b className='underline'>We also require you to upload your availability 1 month in advance.</b>
      Please make sure that this is done in time - if you don’t have availability up online, no one can book you and you will get less appointments!
    </p>
    <p>
      It is also really important to keep your radius preferences updated. We require a radius of at least 10km for all RMT’s, but if you’d like a wider one that’s great! The wider the radius the more chance of you getting appointments.
    </p>
    <h4>
      UPLOADING YOUR ONLINE AVAILABILITY INTO THE RMT PORTAL
    </h4>
    <p>When you upload your availability follow these steps:</p>
    <ol>
      <li>Go to <a href='https://www.rmt.mamamobile.com' target="_blank" className='underline'>rmt.mamamobile.com</a>.</li>
      <li>Go to the dropdown menu at top right under ‘SELECT AN RMT’.</li>
      <li>Select yourself, and only yourself.</li>
      <li>Use the calendar function to select the correct week you need to edit availability for.</li>
      <li>Go to the ‘Shift’ toggle at top right and click it so that it turns pink and the dot is to the right. It should look like this:</li>
      <img width='100px' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1580516957/welcome/avail-2.png' />
      <li>Then click in the spot you’d like to start your shift on the calendar - for example Thursday at 9:00am. A column will pop up to the right like below:</li>
      <li>Here double check the start time, and then specify your end shift time.</li>
      <img width='200px' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1580516957/welcome/avail-1.png' />
      <li>This end time should include an extra hour for travel time at the end of your shift after the point at which you want to stop doing treatments.</li>
      <li>If you need to alter your shift start or end time, you will have to just remove the shift and re-enter your new shift time.</li>
    </ol>
    <h4>To explain what we mention about the ‘extra hour for travel time’...</h4>
    <p>
      The RMT Portal system automatically adds 1 hour of travel time at the end of EVERY appointment. That’s why a 1 hour appointment shows up as 2 hours in the RMT Portal. You therefore need to add an additional hour to every shift after the point in time you wish to stop treating mama’s for that time slot. 
    </p>
    <p>
      For example, on Thursday you’d like to treat mama’s from 9am - 11am. You’re happy to have 1 hour appointments starting at 9am, 9:30am or 10am, or you’d be happy with a 2 hour mama/papa appointment starting at 9am. You therefore have to put your availability in the portal as 9am - 12pm so that there is space for the portal to put in that additional hour of travel time. 
    </p>
    <p>
      Don’t worry, the system will never book you for actual treatment time during that last hour block you have open with availability. However, if you don’t have that last hour open, you won’t have enough availability open for the system to book you in for the appointments you want.
    </p>
    <p>
      Therefore also remember, the smallest amount of availability you can upload online that will allow people to book with you is a 2 hour time slot. That is 1 hour for a 60 minute treatment + the 1 hour travel time buffer.
    </p>
    <h4>Some more tips for adding availability</h4>
    <p>
      The most <b className='underline'>beneficial</b> availability time slot at a minimum for you to input into the RMT Portal is 3 hours. This allows you enough time to book in a Mama/Papa massage with the automatically added 1 hour travel time, and these are our most financially beneficial appointments. 
    </p>
    <p>
      If you only have time to do a 1 hour appointment, that’s totally fine too! Just make sure you put in the extra hour at the end so that your availability shows up as 2 hours not 1. 
    </p>
    <p>
      If you want to do a longer shift and have yourself open for appointments throughout larger sections of the day that’s awesome! Don’t feel like you need to leave a space between blocks of appointments as the system will automatically add the hour travel time after each appointment, which means that you won’t get booked back to back without time to move between your appointments.
    </p>
    <div className='takeaway-box'>
      <h4>TIPS FOR ADDING AVAILABILITY</h4>
      <ul>
        <li>Keep your availability up to date at all times</li>
        <li>Upload availability in the system at a minimum of 1 month in advance</li>
        <li>The most beneficial minimum availability to upload is 3 hours</li>
        <li>Don’t forget to add an additional hour for travel time at the end</li>
        <li>Make sure you ONLY add availability to YOUR calendar</li>
      </ul>
      <p>
        REMEMBER the more time you have up online, the more time you will be able to be booked and the more money you will make! Plus we will have even more happy mama’s in Canada!
      </p>
    </div>
    <h4>AVAILABILITY FOR BOOKING REQUESTS</h4>
    <p>
      Booking requests are an important part of the Mama Mobile service and we thank you all so much for helping find time in your schedules to see additional clients!
    </p>
    <p>
      Please make sure you keep us informed of your preferences with booking requests, whether that is simply that you don’t want to do them, or you can only do them certain days, or even just let us know when you have other jobs or clinics so we can keep record of that information. This way, we can check this information and not hassle you unnecessarily.
    </p>
    <h4>UNDERSTANDING YOUR RADIUS</h4>
    <p>
      Your radius sets the limits for areas in which you are able to be booked online by mamas. The minimum radius we require is 10km, but if you want more let us know!
    </p>
    <p>
      Think of your radius as the 10km straight line point extending outwards from your centre point of reference (most likely your home). The area your radius covers is the area within the larger circle that is spread evenly around your centre point of reference. The edge of the larger circle is exactly 10kms away from that centre point at all times.
    </p>
    <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1580518843/welcome/avail-3.png' width='300px' />
    <p>
      <b className='underline'>This means that although your RADIUS is set at 10km, you still may end up travelling more than 10km to or between appointments.</b>
      You may end up driving more than 10km to your appointment from your house as because of the layout of the roads, as your car covers more ground than a straight line would from your house to your clients. Or you may have one appointment on one side of your radius and your next on the other, meaning that those two appointments are actually further than 10km apart.
    </p>
    <p>If you at any time need to change your radius, please let us know.</p>
  </div>
);

export default Availability;