import React from 'react';
import '@lottiefiles/lottie-player';

const HeroAnimation = ({animation}) => (
  <div>
    <lottie-player
      autoplay
      loop
      mode="normal"
      src={animation}
    />
  </div>
);

export default HeroAnimation;