import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import './login.css';
import WarningBox from '../../Components/WarningBox';

const EMAILS = {
  'cherry@mamamobile.com': true,
};

const PASSWORD = 'MamaSquadRocks';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setErr] = useState(false);

  const handleSubmit = () => {
    if (password === PASSWORD) {
      props.history.push('/home');
    } else {
      setErr(`The password you've entered is incorrect!`);
    }
  }
 
  return (
    <div 
      className='full-page-outter'
      style={{backgroundImage: 
        `linear-gradient(
        rgba(255, 255, 255, 0.3), 
        rgba(255, 255, 255, 0.3)),
        url(https://res.cloudinary.com/dhze7gimq/image/upload/v1580415670/welcome/IMG_2896_2_p3uegb.jpg)`
      }} 
    >
      <div className='login'>
        <h1>Welcome to Mama Squad</h1>
        <WarningBox error={err} />
        <TextField
          required
          name='name'
          onChange={e => setEmail(e.target.value)}
          value={email}
          label='name'
          margin="normal"
          type="name"
          autoComplete="name"
        />
        <TextField
          required
          name='password'
          onChange={e => setPassword(e.target.value)}
          value={password}
          label='password'
          margin="normal"
          type="password"
        />
        <button className='button-outline' onClick={handleSubmit}>
          Enter the Squad
        </button>
      </div>
    </div>
  )
}

export default withRouter(Login);